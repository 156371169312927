<template>
    <!-- Component: organisms/procurements-table -->
    <general-container
        :customClass="'column procurements-table-column'"
        :titleClass="'has-text-centered'"
        :isLocked="false"
        :title="'Procurement Search'"
    >

        <div id="snackbar-procurement">
            <div class="snackbar-container">
                <div @click="hideFlyout()" class="close-item"></div>
                <procurement-flyout
                    v-if="procurementContentId"
                    v-bind="procurementProps"
                    :agency="procurementProps.agency && procurementProps.agency.AgencyRef ? procurementProps.agency.AgencyRef : null"
                ></procurement-flyout>
            </div>
        </div>

        <div class="filter-container container" id="procurementsTableWrapper">

            <div id="reset-filters" @click="resetAllFilters"></div>

            <div class="columns">
                <div class="column">
                    <label for="tbl-agency" class="label">Search by Agency</label>
                    <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="[{value: 'agency', name: 'All Agencies'}].concat(agencies.map(item => ({value: item.locationId, name: item.name})).filter(x => x.name !== 'Legislative Branch' && x.name !== 'Administered Funds'))"
                            name="agencyId"
                            placeholder="Select an Agency..."
                            :dropdown-hide="hideDropdown"
                            v-model:value="agencyId"
                            @update:value="updateFilters"
                        ></custom-select>
                    </div>
                </div>
                <div class="column">
                    <label for="tbl-type" class="label">Search by Type</label>
                    <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="types"
                            name="type"
                            placeholder="Select a Type..."
                            :dropdown-hide="hideDropdown"
                            v-model:value="type"
                            @update:value="updateFilters"
                        ></custom-select>
                    </div>
                </div>

                <div class="column">
                    <label for="tbl-domain" class="label">Search by Domain</label>
                    <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="domains"
                            name="domain"
                            placeholder="Select a Domain..."
                            :dropdown-hide="hideDropdown"
                            v-model:value="domain"
                            @update:value="updateFilters"
                        ></custom-select>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div :id="'applied-filters-' + uniqueId"></div>
                </div>
            </div>

            <div class="columns">
                <div class="column is-full-mobile">
                    <div id="filter-container" class="card">
                        <div class="card-content dark-mode">
                            <div class="content">
                                <div class="search-filter-group">
                                    <div>
                                        <h3 class="content-block__title search-filter-name">Search by Status</h3>
                                        <div class="content-block__content">
                                            <div class="active-only">
                                                <input :id="'status-Active'"
                                                       class="switch is-rounded"
                                                       v-on:click="clickActive($event)"
                                                       type="checkbox"
                                                       :value="'Active'"
                                                >
                                                <label :for="'status-Active'" class="status-filter-label">Show Active Procurements Only</label>
                                            </div>

                                            <ul class="filter-checkboxes">
                                                <li v-for="status in statuses">
                                                    <div class="field">
                                                        <input :id="'status-' + status.value"
                                                               class="switch is-rounded"
                                                               v-on:click="clickStatusFilter($event)" type="checkbox"
                                                               :checked="status.checked ||  (checkedStatuses || []).includes(
                                                                    typeof status.value == 'string'
                                                                    ? typeof status.value
                                                                    : status.value.toString())"
                                                               name="statuses[]"
                                                               :value="status.value">
                                                        <label :for="'status-' + status.value"
                                                               class="status-filter-label">{{ status.name }}</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column is-full-mobile">
                    <div class="card date-range-card">
                        <div class="card-content">
                            <h3><label class="label">Search by Date Range</label></h3>
                            <div class="date-set">
                                <div class="columns">
                                    <div class="column">
                                        <span class="control-title">Beginning Date:</span>
                                        <div class="control">
                                            <div class="control-icon">
                                                <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                            </div>
                                            <div class="control-input">
                                                <Datepicker
                                                    v-model="dateStartStart"
                                                    :clearable="true"
                                                    :inputFormat="'MM/dd/yyyy'"
                                                    :typeable="true"
                                                    :placeholder="'mm/dd/yyyy'"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <span class="control-title">End Date:</span>
                                        <div class="control">
                                            <div class="control-icon">
                                                <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                            </div>
                                            <div class="control-input">
                                                <Datepicker
                                                    v-model="dateStartEnd"
                                                    :clearable="true"
                                                    :inputFormat="'MM/dd/yyyy'"
                                                    :typeable="true"
                                                    :placeholder="'mm/dd/yyyy'"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="filter-input field">
                <label :for="'tbl-keyword-' + uniqueId" class="label display-label">Filter by Keyword</label>
                <div class="search-block">
                    <div class="field has-addons">
                        <div class="control">
                            <input type="search" :id="'tbl-keyword-' + uniqueId" v-model="keyword" class="input search-block__search-input" placeholder="Filter by keyword" />
                            <div class="exact-match">
                                <input :id="'exact-match'"
                                       class="switch is-rounded"
                                       v-on:click="clickExactMatch($event)"
                                       type="checkbox"
                                       :value="'Exact match'"
                                >
                                <label :for="'exact-match'" class="status-filter-label">Exact match</label>
                            </div>
                        </div>

                        <div class="control">
                            <div class="button">
                                <span class="search-block__search-input__icon"><font-awesome-icon :icon="faSearch" aria-label="Calendar icon"></font-awesome-icon></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="filter-info">{{ procurementsTotalCount ||  '0' }} items found</div>
                    <div class="filter-limit">
                        <label>
                            Show
                            <select name="filter-limit--value" v-model="procurementsLimit">
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="1000">1000</option>
                            </select>
                            items
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="procurementsTable tbl responsive-table-800">
            <table class="zebra" id="procurementsTable">
                <thead>
                <tr>
                    <th class="clickable mw-110" @click="sortField = 'status'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Status <span class="sort-status"><span :class="sortField === 'status' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'status' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                    </th>
                    <th class="clickable" @click="sortField = 'agency'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Agency <span class="sort-status"><span :class="sortField === 'agency' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'agency' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                    </th>
                    <th  class="clickable" @click="sortField = 'ad_type'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Ad Type <span class="sort-status"><span :class="sortField === 'ad_type' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'ad_type' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                    </th>
                    <th class="clickable" @click="sortField = 'title'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Title <span class="sort-status"><span :class="sortField === 'title' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'title' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                    </th>
                    <th  scope="col">
                        <Tooltip :tooltip-text="'Incremental version numbers indicate new versions of an existing purchasing document containing updates / corrections'">
                            Version
                        </Tooltip>
                    </th>
                    <th class="clickable" @click="sortField = 'start_date'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Start <span class="sort-status"><span :class="sortField === 'start_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'start_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                    </th>
                    <th class="clickable" @click="sortField = 'end_date'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        End <span class="sort-status"><span :class="sortField === 'end_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'end_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                    </th>
                    <th class="clickable" @click="sortField = 'agency_ad_number'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Number <span class="sort-status"><span :class="sortField === 'agency_ad_number' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortField === 'agency_ad_number' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                    </th>
                </tr>
                </thead>

                <tbody v-if="procurementsTotalCount > 0 "  >
                <template v-for="(procurementGroup, procurementGroupIndex) in procurementsGrouped" :key="'procurementGrouped-' + i">
                    <tr :class=" procurementGroup['procurements'].length > 1 ? 'groupColl' : '' " >
                        <td data-label="Status">
                            <template v-if="procurementGroup['procurements'].length > 1">
                                <template v-if="procurementGroup['procurements'].every( (elem) =>  elem.status == procurementGroup['aggregate'].status )">
                                    {{procurementGroup['aggregate'].status}}
                                </template>
                                <template v-else>
                                    {{  }}
                                </template>
                            </template>
                            <template v-if="procurementGroup['procurements'].length == 1">
                                {{procurementGroup['aggregate'].status}}
                            </template>
                        </td>
                        <td data-label="Agency">
                            <template v-if="procurementGroup['procurements'].length > 1">
                                <template v-if="procurementGroup['procurements'].every( (elem) =>  elem.agency.AgencyRef.name == procurementGroup['aggregate'].agency.AgencyRef.name )">
                                    <a v-if="procurementGroup['aggregate'].agency" :href="procurementGroup['aggregate'].agency.AgencyRef.url">
                                        {{procurementGroup['aggregate'].agency.AgencyRef.name}}
                                    </a>
                                </template>
                                <template v-else>
                                    {{  }}
                                </template>
                            </template>
                            <template v-if="procurementGroup['procurements'].length == 1">
                                <a v-if="procurementGroup['aggregate'].agency" :href="procurementGroup['aggregate'].agency.AgencyRef.url">
                                    {{procurementGroup['aggregate'].agency.AgencyRef.name}}
                                </a>
                            </template>
                        </td>
                        <td data-label="Type">
                            <template v-if="procurementGroup['procurements'].length > 1">
                                <template v-if="procurementGroup['procurements'].every( (elem) =>  elem.adType == procurementGroup['aggregate'].type )">
                                    {{procurementGroup['aggregate'].type}}
                                </template>
                                <template v-else>
                                    {{  }}
                                </template>
                            </template>
                            <template v-if="procurementGroup['procurements'].length == 1">
                                {{procurementGroup['aggregate'].type}}
                            </template>
                        </td>
                        <td data-label="Title" class="title-cell">
                            <template v-if="procurementGroup['procurements'].length > 1">
                                <template v-if="procurementGroup['procurements'].every( (elem) =>  elem.title == procurementGroup['aggregate'].title )">
                                    <div class="title__bookmark">
                                        <bookmark-note-icons
                                            :procurement="procurementGroup['aggregate']"
                                            :customClass="'icon-only'"
                                        />
                                    </div>
                                    <div :class="procurementGroup['aggregate'].title == 'Total' ? 'total total-name' : ''">
                                        <a @click="openFlyout(procurementGroup['aggregate'].locationId)">
                                            {{ titleize(procurementGroup['aggregate'].title) }}
                                        </a>


                                        <button class="contract-button"
                                                :aria-label="titleize(procurementGroup['aggregate'].title)"
                                                @click="openFlyout(procurementGroup['procurements'][0].locationId)"
                                        >
                                            {{ titleize(procurementGroup['aggregate'].title) }}
                                        </button>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="title__bookmark">
                                        <bookmark-note-icons
                                            :procurement="procurementGroup['procurements'][0]"
                                            :customClass="'icon-only'"
                                        />
                                    </div>
                                    <div :class="procurementGroup['aggregate'].title == 'Total' ? 'total total-name' : ''">
                                        <a @click="openFlyout(procurementGroup['aggregate'].locationId)">
                                            {{ titleize(procurementGroup['aggregate'].title) }}
                                        </a>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <a @click="showProcurementGroup(procurementGroupIndex)">
                                    {{ isProcurementGroupShown(procurementGroupIndex) ? '⇧' : '⇩' }} {{ procurementGroup['procurements'].length }}  {{(procurementGroup['aggregate'].rez) ? procurementGroup['aggregate'].rez : 'Expand group to show titles'}}
                                </a>
                            </template>
                            <template v-if="procurementGroup['procurements'].length == 1">
                                <div class="title__bookmark">
                                    <bookmark-note-icons
                                        :procurement="procurementGroup['aggregate']"
                                        :customClass="'icon-only'"
                                    />
                                </div>
                                <div :class="procurementGroup['aggregate'].title == 'Total' ? 'total total-name' : ''">
                                    <a @click="openFlyout(procurementGroup['aggregate'].locationId)">
                                        {{ titleize(procurementGroup['aggregate'].title) }}
                                    </a>
                                </div>
                            </template>
                        </td>
                        <td data-label="Version">
                            <template v-if="procurementGroup['procurements'].length > 1">
                                <template v-if="procurementGroup['procurements'].every( (elem) =>  elem.version == procurementGroup['aggregate'].version )">
                                    {{procurementGroup['aggregate'].version}}
                                </template>
                                <template v-else>
                                    {{  }}
                                </template>
                            </template>
                            <template v-if="procurementGroup['procurements'].length == 1">
                                {{procurementGroup['aggregate'].version}}
                            </template>
                        </td>
                        <td data-label="Start">
                            <template v-if="procurementGroup['procurements'].length > 1">
                                <template v-if="procurementGroup['procurements'].every( (elem) =>  elem.startDateFormatted == procurementGroup['aggregate'].beginDate )">
                                    {{procurementGroup['aggregate'].beginDate}}
                                </template>
                                <template v-else>
                                    {{  }}
                                </template>
                            </template>
                            <template v-if="procurementGroup['procurements'].length == 1">
                                {{procurementGroup['aggregate'].beginDate}}
                            </template>
                        </td>
                        <td data-label="End">
                            <template v-if="procurementGroup['procurements'].length > 1">
                                <template v-if="procurementGroup['procurements'].every( (elem) =>  elem.endDateFormatted == procurementGroup['aggregate'].endDate )">
                                    {{procurementGroup['aggregate'].endDate}}
                                </template>
                                <template v-else>
                                    {{  }}
                                </template>
                            </template>
                            <template v-if="procurementGroup['procurements'].length == 1">
                                {{procurementGroup['aggregate'].endDate}}
                            </template>
                        </td>
                        <template v-if="procurementGroup['procurements'].length > 1">
                            <td data-label="Number" :class="isProcurementGroupShown(procurementGroupIndex) ? 'pg-opened' : 'pg-closed'">
                                 <a @click="showProcurementGroup(procurementGroupIndex)" class="button expand-button">
                                    {{ isProcurementGroupShown(procurementGroupIndex) ? '⇧' : '⇩' }} <span>{{ procurementGroup['procurements'].length }}  {{procurementGroup['aggregate'].agencyAdNumber}}</span>
                                </a>
                            </td>
                        </template>
                        <template v-if="procurementGroup['procurements'].length == 1">
                            <td data-label="Number">
                                {{procurementGroup['aggregate'].agencyAdNumber}}
                            </td>
                        </template>
                    </tr>
                    <tr
                        v-if="procurementGroup['procurements'].length > 1"
                        v-for="(procurement, i) in procurementGroup['procurements']"

                        :class="[
                                 'extra',
                                  isProcurementGroupShown(procurementGroupIndex) ? 'shown' : 'hidden'
                         ]">
                        <td data-label="Status">
                            {{procurement.status}}
                        </td>
                        <td data-label="Agency">
                            <a v-if="procurement.agency" :href="procurement.agency.AgencyRef.url">
                                {{procurement.agency.AgencyRef.name}}
                            </a>
                        </td>
                        <td data-label="Type">
                            {{procurement.adType}}
                        </td>
                        <td data-label="Title" class="title-cell">
                            <div class="title__bookmark">
                                <bookmark-note-icons
                                    :procurement="procurement"
                                    :customClass="'icon-only'"
                                />
                            </div>
                            <div class="title__content">
                                <a @click="openFlyout(procurement.locationId)">
                                    {{ procurement.title }}
                                </a>
                            </div>
                        </td>
                        <td data-label="Version">
                            {{ procurement.version }}
                        </td>
                        <td data-label="Start">
                            {{procurement.startDateFormatted}}
                        </td>
                        <td data-label="End">
                            {{procurement.endDateFormatted}}
                        </td>
                        <td data-label="Number">
                            {{procurement.agencyAdNumber}}
                        </td>
                    </tr>
                </template>

                </tbody>

                <tbody v-else>
                    <td colspan="8">
                        <h5 class="search-info"> "No results found"</h5>
                    </td>
                </tbody>

            </table>

            <div class="preloader-container">
                <preloader v-if="procurementsLoading"/>
            </div>

            <pagination v-if="procurementsSorted && procurementsPageCount > 1"
                        v-on:paginated="paginateProcurements($event)"
                        :current="procurementsPage"
                        :pageCount="procurementsPageCount"
            />

            <div v-if="procurementsGrouped.length > 0 "></div>

        </div>
    </general-container>
    <!-- End Component: organisms/procurements-table -->
</template>

<script>
import RequestHandler from "@/handler/RequestHandler";
import GeneralContainer from "./general-container";
import { nextTick,computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import Preloader from "./preloader";
import Pagination from "./pagination.vue";
import CustomSelect from "./custom-select";
import Datepicker from "vue3-datepicker";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Multiselect from '@vueform/multiselect';
import ProcurementFlyout from "./procurement-flyout";
import BookmarkNoteIcons from "./bookmark-note-icons"
import { v4 as uuidv4 } from "uuid";
import Tooltip from "./tooltip.vue";

export default {
    components: {
        Tooltip,
        Preloader,
        GeneralContainer,
        Pagination,
        CustomSelect,
        Datepicker,
        FontAwesomeIcon,
        Multiselect,
        ProcurementFlyout,
        BookmarkNoteIcons
    },
    props: [
        'title',
        'titleClass',
        'customContainerClass',
        'scrollable',
        'hideAgencyName',
        'procurementFiscalYear'
    ],
    data() {
        return {
            faCalendarDay: faCalendarDay,
            faSearch: faMagnifyingGlass,
            procurementContentId: null,
            procurementProps: null,
            procurementGroupShowing : {},
            showgroup: false
        }
    },
    methods: {
        isProcurementGroupShown(procurementGroupIndex) {
            if (!this.procurementGroupShowing.hasOwnProperty(procurementGroupIndex)) {
                return false;
            }
            return this.procurementGroupShowing[procurementGroupIndex];
        },
        showProcurementGroup(procurementGroupIndex) {
            if (!this.procurementGroupShowing.hasOwnProperty(procurementGroupIndex)) {
                this.procurementGroupShowing[procurementGroupIndex] = true;
                this.random = Math.random();
                this.$forceUpdate();
                return;
            }
            this.procurementGroupShowing[procurementGroupIndex] = !this.procurementGroupShowing[procurementGroupIndex];
            this.random = Math.random();
            this.$forceUpdate();
        },
        openFlyout(locationId) {
            RequestHandler.loadProcurement(locationId).then((data) => {
                if (data && data.ProcurementRef) {
                    this.procurementContentId = data.ProcurementRef.contentId;
                    this.procurementProps = data.ProcurementRef;
                    var x = document.getElementById("snackbar-procurement");
                    x.classList.add('show');
                    x.classList.add('fadein');
                }
            }).catch(e => {
                console.log(e);
            })
        },
        hideFlyout: function() {
            var x = document.getElementById("snackbar-procurement");
            x.classList.remove('fadein');
            x.classList.add('fadeout');
            setTimeout(function(){
                x.classList.remove('show'); x.classList.remove('fadeout');
            }, 500);
        },
        resetDropDown: function () {
            this.typesValues = [];
            let valDiv =  document.getElementById('dmagencyId-' + this.uniqueId);
            valDiv.firstChild.innerHTML = "Select an Agency...";

            this.domainValues = [];
            valDiv = document.getElementById('dmtype-' + this.uniqueId);
            valDiv.firstChild.innerHTML = "Select a Type...";
            this.agencyIds = [];
            valDiv = document.getElementById('dmdomain-' + this.uniqueId);
            valDiv.firstChild.innerHTML = "Select a Domain...";

            this.appliedFilters = [];
            valDiv = document.getElementById('applied-filters-' + this.uniqueId);
            valDiv.innerHTML = "";
        },
        removeFilter: function (elem) {
            let filterValue = ''; let filterName = '';
            if (elem.target.nodeName === 'SPAN') {
                filterValue = elem.target.parentNode.getAttribute('data-value');
                filterName = elem.target.parentNode.getAttribute('data-name');

                elem.target.parentNode.remove();
            } else {
                filterValue = elem.target.dataset.value;
                filterName = elem.target.dataset.name;

                elem.target.remove();
            }

            let valType = false;
            let index = this.appliedFilters.indexOf(filterValue);
            if (index !== -1) {
                this.appliedFilters.splice(index, 1);
            }

            if (filterName === "type") {
                this.typesValues.splice(this.typesValues.indexOf(filterValue),1);
                if (!this.typesValues.length) {
                    valType = 'type';
                }
            } else if (filterName === "domain") {
                this.domainValues.splice(this.domainValues.indexOf(filterValue),1);
                if (!this.domainValues.length) {
                    valType = 'domain';
                }
            } else {
                this.agencyIds.splice(this.agencyIds.indexOf(parseInt(filterValue)),1);
                if (!this.agencyIds.length) {
                    valType = 'agencyId';
                }
            }

            if (valType) {
                this.hideDropdown = true;
                let valSelect = document.getElementById('dm' + valType + '-' + this.uniqueId);
                let valOption = (valType === 'agencyId') ? 'agencies' : valType + 's';
                valSelect.click();

                setTimeout(() => {
                    let itemSelect = document.getElementById(valType + '-all-' + valOption);
                    itemSelect.click();
                    this.hideDropdown = false;
                }, 400)
            } else {
                this.loadProcurements(true);
            }
        },
        resetAllFilters: function () {
            this.resetDropDown();
            this.clearStatusFilter();

            this.keyword = '';
            this.timer = null;
            document.getElementById('tbl-keyword-' + this.uniqueId).value = '';

            this.resetDate = true;
            this.dateStartStart = 0;
            this.dateStartEnd = 0;
            this.dateEndStart = 0;
            this.dateEndEnd = 0;
        },
        updateFilters: function (val) {
            if (this.appliedFilters.indexOf(val.toString()) !== -1) {
                return;
            }

            if (val === 'type'||  val === 'domain' || val === 'agency') {
                let allRemove = document.querySelectorAll("[data-name='" + val + "']");
                for (let i = 0; i < allRemove.length; i++) {
                    allRemove[i].parentNode.removeChild(allRemove[i]);
                }

                if (val === "type") {
                    this.typesValues = [];
                } else if (val === "domain") {
                    this.domainValues = [];
                } else {
                    this.agencyIds = [];
                }

                this.loadProcurements(true);
                return;
            }

            let filter = val; let filterType = '';
            this.appliedFilters.push(filter.toString());

            if (this.types.find(o => o.value === val)) {
                filterType = 'type';
                this.typesValues.push(filter)
            } else if (this.domains.find(o => o.value === val)) {
                filterType = 'domain';
                this.domainValues.push(filter);
            } else {
                filterType = 'agency';
                this.agencyIds.push(filter);

                let agency = this.agencies.find(o => o.locationId === val);
                filter = agency.name;
            }

            let buttonEl = document.createElement('button');
            buttonEl.setAttribute("class", "button-filter button btn");
            buttonEl.setAttribute("data-value", val);
            buttonEl.setAttribute("data-name", filterType);
            buttonEl.addEventListener("click", this.removeFilter);

            let filterClose = document.createElement('span');
            filterClose.setAttribute("style", "padding-right: 10px; font-weight: bold; position: relative; top: -2px; font-size: 21px;");
            filterClose.innerHTML = "× ";
            buttonEl.appendChild(filterClose);
            buttonEl.innerHTML += filter;

            let filterDiv = document.getElementById('applied-filters-' + this.uniqueId);
            filterDiv.appendChild(buttonEl);

            this.loadProcurements(true);
        },
        titleize: function(item) {
            return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
        },
    },
    computed: {
        procurementsGrouped: function() {
            let v = [];
            let seenTitles = [];
            for (let i = 0; i < this.procurements.length; i++) {
                let title = this.procurements[i].agencyAdNumber;
                if (seenTitles.indexOf(title) >= 0) {
                    continue;
                }
                let procurements = [];
                let procurementTitle = this.procurements[i].agencyAdNumber
                procurements.push(this.procurements[i]);
                for (let j = i + 1; j < this.procurements.length; j++) {
                    let jTitle = this.procurements[j].agencyAdNumber;
                    if (title !== jTitle) {
                        continue;
                    }
                    procurements.push(this.procurements[j]);
                    if (procurements.length > 1) {
                        let workList = [];
                        for (let b = 0; b < procurements.length; b++) {
                            workList.push( procurements[b].title)
                                if (procurements[0].title === procurements[b].title){
                                } else {
                                    for (let y = 0; y < workList.length -1; y++) {
                                        let part0 = workList[0].split(' ')
                                        let part1 = workList[y+1].split(' ')
                                        let intersection = (part0.filter(num => part1.includes(num)));
                                        let a = intersection.join( ' ')
                                        this.procurements[i].rez = a
                                    }
                                }
                        }
                    }
                    procurementTitle =procurementTitle !== this.procurements[j].agencyAdNumber ? '' : this.procurements[j].agencyAdNumber;
                }
                seenTitles.push(title);
                let aggregate = {
                    'status': procurements[0].status,
                    'name': procurements[0].agency.AgencyRef.name,
                    'url': procurements[0].url,
                    'type': procurements[0].adType,
                    'title': procurements[0].title,
                    'version': procurements[0].version,
                    'locationId': procurements[0].locationId,
                    'contentId' : procurements[0].contentId,
                    'agencyAdNumber' : procurements[0].agencyAdNumber,
                    'beginDate': procurements[0].startDateFormatted,
                    'endDate': procurements[0].endDateFormatted,
                    'agency': procurements[0].agency,
                    'rez': procurements[0].rez,
                };
                let dateKeys = ['beginDate', 'endDate', 'newEndDate',];
                for (let j = 0; j < procurements.length; j++) {
                    aggregate['totalAmount'] += procurements[j].totalAmount;
                    for (let k = 0; k < dateKeys.length; k++) {
                        if (!procurements[j][dateKeys[k]]) {
                            continue;
                        }
                        let thisDate = new Date(procurements[j][dateKeys[k]]);
                        if (aggregate[dateKeys[k]] === null) {
                            aggregate[dateKeys[k]] = procurements[j][dateKeys[k]];
                            continue;
                        }
                        let aggregateDate = new Date(aggregate[dateKeys[k]]);
                        if (dateKeys[k] === 'beginDate') {
                            if (thisDate < aggregateDate) {
                                aggregate[dateKeys[k]] = procurements[j][dateKeys[k]];
                            }
                        }
                        else {
                            if (thisDate > aggregateDate) {
                                aggregate[dateKeys[k]] = procurements[j][dateKeys[k]];
                            }
                        }
                    }
                }

                let item = {
                    'name': name,
                    'aggregate': aggregate,
                    'procurements': procurements,
                };
                v.push(item);
            }
            return v;
        },
    },
    setup(props) {
        const store = useStore();
        const procurements = ref([]);
        const sortField = ref('end_date');
        const procurementsLoading = ref(true);
        const procurementsSorted = ref([]);
        const procurementsPageCount = ref(null);
        const sortIsAsc = ref(true);
        const hideDropdown = ref(false);
        const procurementsPage = ref(1);
        const procurementsLimit = ref(50);
        const procurementsTotalCount = ref(0);
        const appliedFilters = ref([]);
        const appliedFilterType = ref('');
        const agencies = computed(() => store.getters.agencies || []);
        const agencyId = ref('');
        const agencyIds = ref([]);
        const type = ref('');
        const typesValues = ref([]);
        const types = ref([
            {
                value: 'type',
                name: 'All Types',
            },
            {
                value: 'Agency Decision',
                name: 'Agency Decision',
            },
            {
                value: 'Grant Opportunities',
                name: 'Grant Opportunities',
            },
            {
                value: 'Informational Notice',
                name: 'Informational Notice',
            },
            {
                value: 'Invitation to Bid',
                name: 'Invitation to Bid',
            },
            {
                value: 'Invitation to Negotiate',
                name: 'Invitation to Negotiate',
            },
            {
                value: 'Request for Proposals',
                 name: 'Request for Proposals',
             },
        {
            value: 'Public Meeting Notice',
                name: 'Public Meeting Notice',
        },
        {
            value: 'Request for Information',
                name: 'Request for Information',
        },
        {
            value: 'Request for Statement of Qualifications',
                name: 'Request for Statement of Qualifications',
        },
        {
            value: 'Single Source',
                name: 'Single Source',
        },
    ]);
        const domain = ref('');
        const domainValues = ref([]);
        const domains = ref([
            {
                value: 'domain',
                name: 'All Domains',
            },
            {
                value: 'Health and Human Services',
                name: 'Health and Human Services',
            },
            {
                value: 'Justice and Public Safety',
                name: 'Justice and Public Safety',
            },
            {
                value: 'Environment, Agriculture, and Natural Resources',
                name: 'Environment, Agriculture, and Natural Resources',
            },
            {
                value: 'Infrastructure, Tourism, and Economic Development',
                name: 'Infrastructure, Tourism, and Economic Development',
            },
            {
                value: 'Education',
                name: 'Education',
            },
            {
                value: 'General Government, State Administration, and Technology',
                name: 'General Government, State Administration, and Technology',
            },
            {
                value: 'Legislature - House and Senate',
                name: 'Legislature - House and Senate',
            },
        ]);
        const statuses = ref([
            {
                name: 'Preview',
                value: 'Preview',
                checked: true
            },
            {
                name: 'Open',
                value: 'Open',
                checked: true
            },
            {
                name: 'Withdrawn',
                value: 'Withdrawn',
                checked: false
            },
            {
                name: 'Closed',
                value: 'Closed',
                checked: false
            },
        ]);
        const checkedStatuses = ref(['Open', 'Preview']);
        const resetDate = ref(false);
        const dateStartStart = ref();
        const dateStartEnd = ref();
        const dateEndStart = ref();
        const dateEndEnd = ref();
        const showActive = ref(0);

        const paginateProcurements = (pageTo) => {
            procurementsPage.value = pageTo;
            loadProcurements();
            nextTick(() => {
                scrollToTop();
            });
        };

        // Generate a unique ID for each instance
        const uniqueId = computed(() => {
            return uuidv4();
        });

        const scrollToTop = () => {
            let el = document.getElementById('procurementsTable');
            let navigation = document.getElementById('primary-navigation__container');
            if (!navigation) {
                navigation = document.getElementById('mobile-navigation');
            }

            window.scrollBy(0, -navigation.scrollHeight);
            el.scrollIntoView({behavior: 'smooth'});
        };

        const loadProcurements = (reset) => {
            if (reset) {
                procurements.value = [];
                procurementsPage.value = 1;
                procurementsPageCount.value = null;
                procurementsTotalCount.value = null;
            }
            let originalSearchQuery = keyword.value;
            let searchQuery = keyword.value;
            if (exactMatch.value) {
                searchQuery = '"' + searchQuery + '"';
            }
            procurementsLoading.value = true;
            let activeButton = document.getElementById('status-Active');
            showActive.value = activeButton.checked ? 1 : 0
            let dStartStart = '';
            if (dateStartStart.value) {
                dStartStart = dateStartStart.value.toISOString().split('T')[0];
            }
            let dStartEnd = '';
            if (dateStartEnd.value) {
                dStartEnd = dateStartEnd.value.toISOString().split('T')[0];
            }
            let dEndStart = '';
            if (dateEndStart.value) {
                dEndStart = dateEndStart.value.toISOString().split('T')[0];
            }
            let dEndEnd = '';
            if (dateEndEnd.value) {
                dEndEnd = dateEndEnd.value.toISOString().split('T')[0];
            }
            RequestHandler.loadProcurements(
                searchQuery,
                (procurementsPage.value - 1) * procurementsLimit.value,
                procurementsLimit.value,
                sortField.value,
                sortIsAsc.value,
                agencyIds.value.toString(),
                typesValues.value.toString(),
                domainValues.value,
                showActive.value,
                checkedStatuses.value.toString(),
                dStartStart,
                dStartEnd,
                dEndStart,
                dEndEnd
            ).then(response => {
                if (originalSearchQuery !== keyword.value) {
                    return;
                }
                resetDate.value = false;
                procurementsLoading.value = false;
                procurements.value = response.ContentList.list;
                procurementsPageCount.value = response.ContentList.pageCount;
                procurementsTotalCount.value = response.ContentList.totalCount;
                setProcurements();
            });
        };
        const setProcurements = () => {
            let d = procurements.value;

            function compare(a, b) {
                if (a[sortField.value] < b[sortField.value]) {
                    return sortIsAsc.value ? -1 : 1;
                }
                if (a[sortField.value] > b[sortField.value]) {
                    return sortIsAsc.value ? 1 : -1;
                }
                return 0;
            }

            procurementsSorted.value = d.sort(compare);
        };

        const clickActive = (event) => {
            clearStatusFilter();
            showActive.value = event.target.checked ? 1 : 0;

            nextTick(() => {
                loadProcurements(true);
            });
        }

        const clearStatusFilter = () => {
            checkedStatuses.value = [];
            let allStatusInputs = document.querySelectorAll('[name="statuses[]"]');
            for (let i = 0; i < allStatusInputs.length; i++) {
                if (allStatusInputs[i].checked) {
                    allStatusInputs[i].checked = false;
                }
            }
        }

        const clickStatusFilter = (event) => {
            let name = event.target.name;
            let checked = event.target.checked;
            let value = event.target.value;
            let filters = checkedStatuses.value || [];
            let index = filters.indexOf(value);
            if (checked) {
                filters.push(value);
            } else {
                if (index !== -1) {
                    filters.splice(index, 1);
                }
            }
            checkedStatuses.value = filters;

            if (showActive.value) {
                let activeIn = document.getElementById('status-Active');
                activeIn.checked = false;
                showActive.value = 0;
            }

            nextTick(() => {
                loadProcurements(true);
            });
        };

        onMounted(() => {
            loadProcurements();
        });

        const keyword = ref('');
        let timer = null;
        watch(keyword, (newVal) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => loadProcurements(true), 300);
        });
        watch(dateStartStart, (now) => {
            if (!resetDate.value){
                loadProcurements(true);
            }
        });
        watch(dateStartEnd, (now) => {
            if (!resetDate.value){
                loadProcurements(true);
            }            });
        watch(dateEndStart, (now) => {
            if (!resetDate.value){
                loadProcurements(true);
            }            });
        watch(dateEndEnd, (now) => {
            if (!resetDate.value){
                loadProcurements(true);
            }
        });
        watch(procurementsLimit, (now) => {
            loadProcurements(true);
        });

        const exactMatch = ref(0);
        const clickExactMatch = (event) => {
            exactMatch.value = event.target.checked ? 1 : 0;

            nextTick(() => {
                loadProcurements(true);
            });
        }

        return {
            keyword,
            procurements,
            procurementsLoading,
            procurementsPageCount,
            procurementsLimit,
            procurementsTotalCount,
            hideDropdown,
            sortIsAsc,
            sortField,
            procurementsSorted,
            agencies,
            agencyId,
            agencyIds,
            types,
            typesValues,
            type,
            domain,
            domainValues,
            domains,
            statuses,
            clickStatusFilter,
            clickActive,
            clearStatusFilter,
            checkedStatuses,
            appliedFilters,
            appliedFilterType,
            resetDate,
            dateStartStart,
            dateStartEnd,
            dateEndStart,
            dateEndEnd,
            loadProcurements,
            paginateProcurements,
            clickExactMatch,
            procurementsPage,
            uniqueId,
        };
    }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('search-block') {
        position: relative;

        @include element('container') {
            display: flex;
            justify-content: flex-start;
        }

        .control:first-of-type {
            @media screen and (min-width: 1216px) {
                width: calc(100% - 60px) !important;
            }
            @media screen and (max-width: 960px) {
                width: calc(100% - 60px) !important;
            }
            @media screen and (max-width: 768px) {
                width: calc(100% - 40px) !important;
            }
        }

        .control:last-of-type {
            @media screen and (min-width: 1216px) {
                width: 60px !important;
            }
            @media screen and (max-width: 960px) {
                width: 60px !important;
            }
            @media screen and (max-width: 768px) {
                width: 40px !important;
            }
        }

        @include element('search-input') {
            border: 2px solid $blue--dark;
            border-right: none;
            @include element('icon') {
                svg {
                    width: 24px !important;
                    height: 36px !important;
                }
            }
        }

        .button {
            height: 75px;
            width: 100%;
            background-color: $white;
            color: $grey--lighter;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border: 2px solid $blue--dark;
            border-left: none;
            padding-right: 0.5em;
            cursor: default !important;
        }

        input[type="search"]::-webkit-search-decoration:hover,
        input[type="search"]::-webkit-search-cancel-button:hover {
            cursor:pointer;
        }
    }

    .tbl thead th {
        color: #fff;
        position: -webkit-sticky;
        position: sticky;
    }
    .procurements-table-column {
        padding-left: 0px;
        padding-right: 0px;
    }

    .filter-container {
        height: 100%;
        .card-content {
            height: 100%;
        }
    }

    .filter-limit {
        text-align: right;
        margin-bottom: .75rem;
    }

    .filter-input {
        .label {
            display: none;
        }
    }
    #tbl-keyword {
        height: 75px;
    }
    .filter-info {
        text-align: center;
        font-size: 21px;
        font-weight: 600;
    }

    .custom-select {
        width: 100%;
    }

    .filter-checkboxes {
        list-style-type: none;
        margin-left: 0px;
        column-count: 2;
    }

    .dark-mode {
        background-color: $blue--dark;
        color: $white;
    }

    .search-filter-name {
        border-bottom: 1px solid $blue--med;
        color: $white !important;
        font-weight: 600;
        font-family: Fira Sans,sans-serif;
        font-size: 1rem;
        margin-bottom: 1em;
        padding-bottom: 10px;
    }

    .date-range-card {
        .control {
            margin-bottom: 10px;
            display: flex;
            .control-icon {
                float: left;
                padding-right: 10px;
                color: $blue--dark;
                font-size: 28px;
                padding-top: 15px;
            }
            .control-input {
                float: left;
                display: inline-block;
                position: relative;
                top: 7px;
            }
        }
        .control-title {
            display: block;
            width: 100%;
        }
    }

    h3 {
        label {
            font-size: 1rem;
            line-height: 1.1rem;
            margin-bottom: 1em;
            padding-bottom: 10px;
            border-bottom: 1px solid $blue--med;
            &.sub-label {
                border-bottom: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
        }
    }

    .inactive-sort {
        opacity: 0.45;
    }
    .active-sort {
        opacity: 1.0;
    }

    .mw-110 {
        min-width: 110px;
    }

    .date-set {
        padding: 10px;
        .columns {
            margin-bottom: 0px;
            .column {
                padding-bottom: 0px;
            }
        }
        &:nth-of-type(even) {
            background-color: $grey--light;
        }
        h3 {
            line-height: 0em;
            margin-bottom: 10px;
        }
    }

    // based on https://www.w3schools.com/howto/howto_js_snackbar.asp
    /* The snackbar - position it at the bottom and in the middle of the screen */
    #snackbar-procurement {
        visibility: hidden; /* Hidden by default. Visible on click */
        min-width: 250px; /* Set a default minimum width */
        max-width: 95vw; /* for mobile */
        margin-left: -125px; /* Divide value of min-width by 2 */
        padding: 0px; /* Padding */
        position: fixed; /* Sit on top of the screen */
        z-index: 9999; /* allows to display in front of nav */
        right: 15px;
        bottom: 35px;
        max-height: 90vh;
        overflow-y: scroll;
        background-color: $blue--lighter !important;
        color: $blue--dark !important;
        box-shadow: $box-shadow !important;
        border-radius: 8px;
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */
    #snackbar-procurement.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
        &.fadein {
            -webkit-animation: fadein 0.5s;
            animation: fadein 0.5s;
        }
        &.fadeout {
            -webkit-animation: fadeout 0.5s;
            animation: fadeout 0.5s;
        }
    }

    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    .close-item:before {
        font-size: 30px;
        cursor: pointer;
        content: "×";
        color: $blue--dark;
        transition: color .3s;
        font-weight: 600;
        margin-left: 10px;
        position: relative;
        float: right;
        top: -5px;
        left: -5px;
    }

    .sort-status {
        span:last-of-type {
            position: relative;
            top: -1px;
        }
    }

    .active-only {
        background-color: rgba(51, 116, 170, 0.3);
        text-align: center;
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #reset-filters {
        display: none;
    }

    .title__bookmark {
        width: 25px;
    }
    .title__content {
        display: inherit;
    }

    .display-label {
        display: inline-block !important;
    }

    .close-item {
        position: absolute;
        right: 20px;
        z-index: 1;
    }

    .extra.hidden {
        display: none;
    }
    .extra.shown {
    }
    .extra td:first-child a {
        margin-left: 10px;
    }
    @media screen and (max-width: 800px) {
        .procurementsTable table tbody tr td.title-cell {
            padding-bottom: 0px;
            .title__bookmark {
                display: initial;
                .card__tools.icon-only {
                    display: contents;
                    // if you want to style anything below this level you need to do it in the _required.scss sheet
                }
            }
            .title__content {
                display: inherit;
                position: relative;
                top: -9px;
            }
        }
    }

    .button-filter.button.btn {
        &:focus, &:hover {
            color: $white;
        }
    }

    .search-block__search-input {
        height: 75px;
    }
    .search-info {
        text-align: center;
    }

    .pg-opened {
        background-color:  $grey--med;
    }

    tr.extra.shown {
        background-color: $grey--med;
        a, span, button {
            color: $blue--dark;
        }
    }
</style>
